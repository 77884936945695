class API {
  baseURL: string
  authHeader?: string

  public constructor(baseURL = "/api/v1") {
    this.baseURL = baseURL
  }

  public setTokenAuthHeader(token: string) {
    this.authHeader = "Bearer " + token
  }

  public setBasicAuthHeader(login: string, pw: string) {
    this.authHeader = 'Basic ' + btoa(login + ':' + pw)
  }

  public get(endpoit: string, payload?: any) {
    return this.doRequest(endpoit, "GET", payload)
  }

  public post(endpoit: string, payload?: any) {
    return this.doRequest(endpoit, "POST", payload)
  }

  public put(endpoit: string, payload?: any) {
    return this.doRequest(endpoit, "PUT", payload)
  }

  public patch(endpoit: string, payload?: any) {
    return this.doRequest(endpoit, "PATCH", payload)
  }

  public delete(endpoit: string) {
    return this.doRequest(endpoit, "DELETE")
  }

  private async doRequest(endpoit: string, method: string, payload?: any) {
    if (!this.authHeader || (this.authHeader && this.authHeader.length === 0)) {
      const token = localStorage.getItem('TOKEN')
      if (token) {
        this.setTokenAuthHeader(token)
      } else if (!this.authHeader) {
        return null
      }
    }

    return await fetch(this.baseURL + endpoit, {
      method: method,
      headers: new Headers({
        "Authorization": (this.authHeader || ""),
        "Content-Type": "application/json"
      }),
      body: JSON.stringify(payload)
    }).then((r) => {
      if (r.ok) {
        return r.json()
      }
      return null
    })
  }
}

export default new API();
