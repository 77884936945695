import './App.css';
import 'semantic-ui-css/semantic.min.css'
import { Container } from 'semantic-ui-react'
import Overview from './Overview'
import HeaderBar from './Headerbar'
import LoginModal from './LoginModal'

function App() {
  return (
      <Container style={{ marginTop: '0.5em' }}>
        <LoginModal/>
        <HeaderBar/>
        <Overview/>
      </Container>
  );
}

export default App;
