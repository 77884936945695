
import {useEffect, useState} from 'react';
import API from "./API"

export interface Overview {
	Date: Date;
	WeekNumber: number;
	OvertimeThisDayInMinutes: number;
	ActiveTimeThisDayInMinutes: number;
	OvertimeThisWeekInMinutes: number;
	ActiveTimeThisWeekInMinutes: number;
	OvertimeThisMonthInMinutes: number;
	ActiveTimeThisMonthInMinutes: number;
	OvertimeThisYearInMinutes: number;
	ActiveTimeThisYearInMinutes: number;
	ActiveActivity: Activity | null;
}

export interface Activity {
	Start: Date;
	End: Date;
	Description: string;
}

export const useGetOverview = (): [Overview | null, boolean] => {
	const [loading, setLoading] = useState(false);
	const [overview, setOverview] = useState<Overview | null>(null);


	useEffect(() => {
		if (overview === null) {
			setLoading(true)
			API.get("/overview").then((r) => setOverview(r)).finally(() => setLoading(false))
		}
	}, [overview])

	return [overview, loading]
}
