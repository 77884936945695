import {Grid, Statistic} from 'semantic-ui-react'
import {useGetOverview} from "./useGetOverview"

const fm = (mins: number): string => {
  const h = mins/60;
  const rh = Math.floor(h);
  const m = mins % 60;

  return rh + ":" + m.toString().padStart(2, "0")
}

const Overview = () => {
  const [overview, loading] = useGetOverview()

  return (
    <>
      {!loading && overview && (
        <div>
          <Grid columns={4} doubling stackable>
            <Grid.Column>
              <Statistic>
                <Statistic.Value>{fm(overview.OvertimeThisDayInMinutes)}</Statistic.Value>
                <Statistic.Label>Daily Overtime</Statistic.Label>
              </Statistic>
            </Grid.Column>
            <Grid.Column>
              <Statistic>
                <Statistic.Value>{fm(overview.OvertimeThisWeekInMinutes)}</Statistic.Value>
                <Statistic.Label>Weekly Overtime</Statistic.Label>
              </Statistic>
            </Grid.Column>
            <Grid.Column>
              <Statistic>
                <Statistic.Value>{fm(overview.OvertimeThisMonthInMinutes)}</Statistic.Value>
                <Statistic.Label>Monthly Overtime</Statistic.Label>
              </Statistic>
            </Grid.Column>
            <Grid.Column>
              <Statistic>
                <Statistic.Value>{fm(overview.OvertimeThisYearInMinutes)}</Statistic.Value>
                <Statistic.Label>Yearly Overtime</Statistic.Label>
              </Statistic>
            </Grid.Column>
            <Grid.Column>
              <Statistic>
                <Statistic.Value>{fm(overview.ActiveTimeThisDayInMinutes)}</Statistic.Value>
                <Statistic.Label>Daily Active Time</Statistic.Label>
              </Statistic>
            </Grid.Column>
            <Grid.Column>
              <Statistic>
                <Statistic.Value>{fm(overview.ActiveTimeThisWeekInMinutes)}</Statistic.Value>
                <Statistic.Label>Weekly Active Time</Statistic.Label>
              </Statistic>
            </Grid.Column>
            <Grid.Column>
              <Statistic>
                <Statistic.Value>{fm(overview.ActiveTimeThisMonthInMinutes)}</Statistic.Value>
                <Statistic.Label>Monthly Active Time</Statistic.Label>
              </Statistic>
            </Grid.Column>
            <Grid.Column>
              <Statistic>
                <Statistic.Value>{fm(overview.ActiveTimeThisYearInMinutes)}</Statistic.Value>
                <Statistic.Label>Yearly Active Time</Statistic.Label>
              </Statistic>
            </Grid.Column>
          </Grid>
        </div>
      )
      }
    </>
  )
}

export default Overview
