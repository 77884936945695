import {useEffect, useState} from "react"
import {Button, Form, Header, Image, Modal} from 'semantic-ui-react'
import {useCreateToken} from "./useCreateToken"


export default function LoginModal() {
  const [create, token, loaded] = useCreateToken()
  const [validAuth, setValidAuth] = useState(false)
  const [inputLogin, setInputLogin] = useState("")
  const [inputPW, setInputPW] = useState("")

  useEffect(() => {
    let token = localStorage.getItem("TOKEN")

    if (!token || token.length === 0) {
      setValidAuth(false)
    } else {
      setValidAuth(true)
    }
  }, [setValidAuth, inputLogin, inputPW]) 

    useEffect(() => {
      if (token && loaded) {
        localStorage.setItem("TOKEN", token.Token)
        setValidAuth(true)
      } else if (loaded){
        setInputPW("")
        setInputLogin("")
      }
  }, [loaded, token])

  return (
    <Modal
      open={!validAuth}
      size='small'
      dimmer="blurring"
    >
      <Header icon>
        <Image src='icon.png' size='medium' circular />
        Your Overtime
      </Header>
      <Modal.Content>
        <Form>
          <Form.Group widths='equal'>
            <Form.Field>
              <Form.Input label="Login" placeholder='MaxMus' value={inputLogin} onChange={(e) => {setInputLogin(e.target.value)}} />
            </Form.Field>
            <Form.Field>
              <Form.Input label="Password" placeholder='secret' type="password" value={inputPW} onChange={(e) => {setInputPW(e.target.value)}} />
            </Form.Field>
          </Form.Group>
          <Button type='submit' onClick={() => create(inputLogin, inputPW)}>Submit</Button>
        </Form>
      </Modal.Content>
    </Modal>
  )
}
