import React from 'react'
import { Menu } from 'semantic-ui-react'

const items = [
  { key: 'start', active: true, name: 'Start' },
  { key: 'settings', name: 'Settings' },
]

export default function HeaderBar() {
  return (
    <Menu items={items} />
  )
}
